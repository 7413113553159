import { useEffect, useState } from "react";
import "../css/generale.css";
import { db } from "../firebase-config";
import { collection, doc, getDoc, getDocs, limit, orderBy, query, startAfter, startAt, where } from "firebase/firestore";
import { Link } from "react-router-dom";

export default function Blog() {
    const [articoli, setArticoli] = useState([])
    const [partner, setPartner] = useState([])
    const [categoriaSelected, setCategoriaSelected] = useState(0)
    const [categorie, setCategorie] = useState([])
    const [index, setIndex] = useState({ a: 0, b: 0 })
    const [filtro, setFiltro] = useState("timestamp")
    const [order, setOrder] = useState("desc")
    const [operatore, setOperatore] = useState(">")
    const [full, setFull] = useState(false)
    const [first, setFirst] = useState(query(
        collection(db, "blog"),
        where(filtro, operatore, categoriaSelected),
        orderBy(filtro, order),
        limit(6)
    ), [])

    useEffect(() => {
        setArticoli([])
        setFull(false)
        setIndex(({
            a: 0,
            b: 0
        }));
        setFirst(query(
            collection(db, "blog"),
            where(filtro, operatore, categoriaSelected),
            orderBy(filtro, order),
            limit(6)
        ))
    }, [categoriaSelected])

    useEffect(() => {
        const getArticoli = async () => {
            const raccolta = await getDocs(first);
            const mappatura = raccolta.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            if (mappatura.length != 0) setArticoli(articoli.concat(mappatura));
        }
        getArticoli();
    }, [first])

    // ottengo la porzione successiva della raccolta
    const Refresh = (dove) => {
        switch (dove) {
            case "previous":
                if (index.a > 5) {
                    setIndex(prevState => ({
                        //b-a = larghezza intervallo
                        ...prevState,
                        a: prevState.a - 6,
                        b: prevState.b - (prevState.b - prevState.a) - 1
                    }));
                }
                break
            case "next":
                if (index.b == articoli.length - 1 && !full) {
                    var lastVisible = articoli[articoli.length - 1]
                    const next = query(
                        collection(db, "blog"),
                        where(filtro, operatore, categoriaSelected),
                        orderBy(filtro, order),
                        startAfter(lastVisible[filtro]),
                        limit(6)
                    )
                    const getArticoli = async () => {
                        const raccolta = await getDocs(next);
                        const mappatura = raccolta.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                        setArticoli(articoli.concat(mappatura))
                        setIndex(prevState => ({
                            ...prevState,
                            //b-a = larghezza intervallo
                            a: prevState.a + prevState.b - prevState.a + 1
                        }));
                    }
                    getArticoli();
                    //l'index b si aggiorna grazie all'useEffect che rileva l'aggiunta di prodotti
                }
                else if (!full || full && index.b < articoli.length - 1) {
                    // caso in cui rimangono meno elementi dell'intervallo standard (ultima pagina)
                    if (articoli.length - index.b < index.b - index.a) {
                        setIndex(prevState => ({
                            ...prevState,
                            a: prevState.a + (prevState.b - prevState.a) + 1,
                            b: prevState.b + (articoli.length - prevState.b) - 1
                        }));
                    }
                    // caso standard in cui la prossima pagina non è l'ultima
                    else setIndex(prevState => ({
                        ...prevState,
                        a: prevState.a + (prevState.b - prevState.a) + 1,
                        b: prevState.b + (prevState.b - prevState.a) + 1
                    }));
                }
                break
        }
    }

    useEffect(() => {
        setIndex(prevState => ({
            ...prevState,
            b: articoli.length - 1
        }))
        // CONTROLLO SE HO ALMENO UN ELEMENTO NON ANCORA MOSTRATO
        const getArticoli = async () => {
            var lastVisible = articoli[articoli.length - 1]
            const check = query(
                collection(db, "blog"),
                where(filtro, operatore, categoriaSelected),
                orderBy(filtro, order),
                startAfter(lastVisible ? lastVisible[filtro] : null),
                limit(1)
            )
            const documentoProva = await getDocs(check);
            const mappaturaProva = documentoProva.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            if (mappaturaProva.length == 0) setFull(true)
        }
        getArticoli();
    }, [articoli])

    useEffect(() => {
        const getInfo = async () => {
            const informazioni = await getDoc(doc(db, "gestione", "infoBase"))
            setCategorie(informazioni.data()["categorieArticoli"])
        }
        getInfo()
        const getPartner = async () => {
            const raccolta = await getDoc(doc(db, "gestione", "Partner"))
            const mappatura = Array.from(Object.values(raccolta.data()), (persona) => {
                return {
                  nome: persona.nome,
                  foto: persona.foto,
                  biografia: persona.biografia
                };
              });
            setPartner(mappatura)
        }
        getPartner();
    }, [])

    return (
        <div className="contenitoreVerticale" id="blog">
            <br />
            <div className="pages" style={{ color: "white", backgroundColor: "black", paddingInline: "1vw" }}>
                <ion-icon name="book-outline" />
                <h2 style={{ margin: 0 }}>Articoli</h2>
            </div>
            <br className="hideOnDesktop" />
            <ul className="filtra">
                {categorie.length > 1 ? categorie.map((categoria, index) => {
                    return (
                        <li id={categoria} key={index}
                            className={categoriaSelected == categoria ? "categoriaActive" : "categoria"}
                            onClick={() => {
                                if (categoriaSelected == categoria) {
                                    setCategoriaSelected(0)
                                    setOperatore(">")
                                    setFiltro("timestamp")
                                    setOrder("desc")
                                }
                                else {
                                    setCategoriaSelected(categoria)
                                    setOperatore("array-contains")
                                    setFiltro("categorie")
                                    setOrder("asc")
                                }
                            }}>
                            {categoria}
                            {categoriaSelected == categoria ?
                                <ion-icon name="close-circle-outline" /> : null}
                        </li>
                    )
                }) : null}
            </ul>
            <br />
            <div className="contenitoreOrizzontale" id="blog">
                {articoli.slice(index.a, index.b + 1).map((articolo) => {
                    return (
                        <article key={articolo["id"]} className="prodotto"
                            style={{ justifyContent: "flex-start", border: 0 }}>
                            <Link to={"/" + articolo["nome"]} className="image"
                                onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}>
                                <img src={articolo["imgURL"]} loading="lazy" />
                            </Link>
                            <br />
                            <Link to={"/" + articolo["nome"]}
                                style={{ textDecoration: "none" }}
                                onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}>
                                <h1 className="seeMore" style={{ textAlign: "center" }}>
                                    {articolo["nome"]}
                                </h1>
                            </Link>
                            <p>pubblicato il: {articolo.data}</p>
                        </article>
                    )
                })}
            </div>
            <br className="hideOnDesktop" />
            <div className="contenitoreOrizzontale">
                <p className="pages">
                    {index.a == 0 ? <ion-icon name="radio-button-off-outline"></ion-icon>
                        : <ion-icon name="caret-back-circle-outline" onClick={() => Refresh("previous")} />}
                    ESPLORA
                    {full && index.b == articoli.length - 1 ? <ion-icon name="radio-button-off-outline"></ion-icon>
                        : <ion-icon name="caret-forward-circle-outline" onClick={() => Refresh("next")} />}
                </p>
            </div>
            <br />
            {partner != "" ? <div className="pages" style={{ color: "white", backgroundColor: "black", paddingInline: "1vw" }}>
                <ion-icon name="people-circle-outline"></ion-icon>
                <h2 style={{ margin: 0, backgroundColor: "black" }}>Partner</h2>
            </div> : null}
            {partner ? partner.map((persona) => {
                return (
                    <div key={persona.nome}>
                        <br className="hideOnDesktop" />
                        <div className="contenitoreVerticale">
                            <div className="grid" style={{ flexDirection: "column", backgroundColor: "transparent", overflow: "hidden" }}>
                                <img src={persona.foto} loading="lazy" alt="" style={{ border: 0, borderRadius: "100vw" }} />
                                <h2 style={{ color: "white" }}>{persona.nome}</h2>
                            </div>
                            <p style={{ color: "white", textAlign: "justify" }}>{persona.biografia}</p>
                        </div>
                        <br />
                    </div>
                )
            }
            )
                : null
            }
            <br />
        </div>
    )
}