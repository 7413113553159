import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase-config";

export default function About() {
    const [domande, setDomande] = useState([])
    const [linkModulo, setModulo] = useState([""])
    const [linkTermini, setTermini] = useState([""])
    const [linkPrivacy, setPrivacy] = useState([""])
    useEffect(() => {
        const getInfo = async () => {
            const informazioni = await getDoc(doc(db, "gestione", "infoBase"));
            setDomande(informazioni.data()["faq"])
            setModulo(informazioni.data()["moduloReso"])
            setTermini(informazioni.data()["linkTermini"])
            setPrivacy(informazioni.data()["linkPrivacy"])
        }
        getInfo()
    }, [])

    return (
        <div className="contenitoreVerticale">
            <h1> <ion-icon name="help-circle-outline"></ion-icon> Domande frequenti </h1>
            <ul className="cartList" style={{ flexDirection: "column" }}>
                <br />
                {domande.map((domanda) => {
                    return (
                        <li>
                            <div className="caption">
                                <h2 style={{ fontWeight: "bold" }}>➡️ {domanda["domanda"]}</h2>
                                <p>{domanda["risposta"]}</p>
                            </div>
                        </li>
                    )
                })}
                <br />
                <hr />
                <p className="footer">🔗 Link utili</p>
                <div className="contenitoreOrizzontale" style={{justifyContent:"space-evenly", width:"100%"}}>
                    <h2 style={{ color: "blueviolet", margin: 0 }}> (*) <a style={{ color: "blueviolet" }} href={linkModulo} target="_blank">
                        form restituzione articoli </a>
                    </h2>
                    <h2 style={{ color: "blueviolet" }}>
                        <div className="contenitoreOrizzontale">
                            <p style={{ color: "blueviolet" }}>(**) </p>
                            <a href="https://www.instagram.com/greylovestore/" target="_blank" style={{ display: "flex", color: "blueviolet", alignItems: "center" }}>
                                <ion-icon name="logo-instagram" id="footer_icon"></ion-icon>
                            </a>
                            <a href="https://www.facebook.com/greylovestore/" target="_blank" style={{ display: "flex", color: "blueviolet", alignItems: "center" }}>
                                <ion-icon name="logo-facebook" id="footer_icon"></ion-icon>
                            </a>
                            <a href="mailto:info@greylovestore.com" style={{ display: "flex", color: "blueviolet", alignItems: "center" }}>
                                <ion-icon name="mail-outline" id="footer_icon"></ion-icon>
                            </a>
                        </div>
                    </h2>
                    <h2 style={{ marginTop: 0, color: "blueviolet" }}>( ! ) <a style={{ color: "blueviolet" }} href={linkTermini} target="_blank">
                            termini e condizioni
                        </a>
                    </h2>
                    <h2 style={{ marginTop: 0, color: "blueviolet" }}>
                        <a style={{ color: "blueviolet" }} href={linkPrivacy} target="_blank">
                            privacy
                        </a>
                    </h2>
                </div>
                <br /><br />
            </ul>
            <br />
        </div>
    );
} 