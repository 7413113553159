import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase-config";
import { useAuth } from "../contexts/AuthContext";

export default function OrderHistory() {
    const [ordini, setOrdini] = useState([]);
    const { currentUser } = useAuth()
    useEffect(() => {
        const getOrdini = async () => {
            const raccolta = await getDocs(
                query(
                    collection(db, "orders"),
                    where("userId", "==", currentUser.uid),
                    orderBy("timestamp", "desc"),
                )
            )
            const mappatura = raccolta.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            if (mappatura.length != 0) setOrdini(ordini.concat(mappatura))
        }
        getOrdini()
    }, [])

    return (
        <div className="contenitoreVerticale" id="orderHistory" style={{ overflow: "scroll" }}>
            <br className="hideOnMobile"/>
            <h1 style={{ display: "flex", alignItems: "center", gap: "1vw", margin:0 }}>
                <ion-icon name="receipt-outline" /> I tuoi ordini
            </h1>
            <br className="hideOnMobile"/>
            {ordini.length > 0 ? ordini.map((ordine) => {
                var date = new Date(ordine.timestamp * 1000);

                return (
                    <article key={ordine.id} className="prodotto" id="prodottoBig">
                        <p> ➡️ Ordine del <b>{date.toLocaleDateString()}</b>,
                            ore {date.toLocaleTimeString()} |
                            Totale: <b>€ {ordine.amountTotal / 100}</b> |
                            Stato attuale: <b>{ordine.paymentStatus == "paid" ? "ORDINE EFFETTUATO" : ordine.paymentStatus}</b>
                            <br />
                            ➡️ ID ordine: <i>{ordine.id}</i>
                        </p>
                    </article>
                )
            })
                : <h1 style={{ color: "grey" }}><br /> Ancora nessun acquisto!</h1>}
        </div>
    )
}