import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase-config";

export default function Signup() {
    const emailRef = useRef()
    const pwRef = useRef()
    const { signup, currentUser } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const [linkTermini, setTermini] = useState([""])
    const [linkPrivacy, setPrivacy] = useState([""])
    useEffect(() => {
        const getInfo = async () => {
            const informazioni = await getDoc(doc(db, "gestione", "infoBase"));
            setTermini(informazioni.data()["linkTermini"])
            setPrivacy(informazioni.data()["linkPrivacy"])
        }
        getInfo()
    }, [])

    async function handleSubmit(e) {
        setError('')
        setLoading(true)
        e.preventDefault()
        try {
            await signup(emailRef.current.value, pwRef.current.value)
            navigate("/login")
        } catch {
            setError("Qualcosa è andato storto!")
        }
        setLoading(false)
    }

    return (
        <form onSubmit={handleSubmit} className="contenitoreVerticale">
            <br/>
            <br/>
            <h1> Registrati </h1>
            {error &&
                <h2 style={{ display: "flex", alignItems: "center" }}> <ion-icon name="alert-circle-outline"></ion-icon> {error} </h2>}
            <input type="email" minLength="6" ref={emailRef} className="form" placeholder="email" required></input>
            <input type="password" minLength="6" ref={pwRef} className="form" placeholder="password" required></input>
            <input type="submit" className="form" id="submit" value="Registrati" disabled={loading}></input>
            <p style={{textAlign:"center"}}> Hai già un account? Effettua il <Link to="/login">login</Link></p>
            <br className="hideOnDesktop"/>
            <br/>
            <div style={{ width: "70vw", textAlign:"justify"}}>
                <label style={{display:"flex", gap:"1vw"}}> <input type="checkbox" required></input>
                    Dichiaro di aver letto e di accettare le 
                    Condizioni generali di vendita, 
                    ai sensi dell'art. 10 comma 3 del D.lgs. 70/2003, ivi compreso quanto indicato al punto G
                    delle suddette Condizioni.
                </label>
                <br/>
                <label style={{display:"flex", gap:"1vw"}}>
                    <input type="checkbox" required id="check2"></input>
                    Dichiaro di accettare quanto indicato al punto G delle Condizioni generali di contratto,
                    secondo quanto indicato dall’articolo 52 e segg. del D. Lsg. 206 del 2005 (Codice del consumo).
                </label>
                <p style={{color:"grey", textAlign:"center"}}>
                    <a href={linkTermini} style={{color:"grey"}} target="_blank">termini e condizioni
                    </a> • <a 
                    href={linkPrivacy} style={{color:"grey"}} target="_blank">privacy</a>
                </p>
            </div>
        </form>
    )
}