import { useAuth } from "../contexts/AuthContext";
import { useState, useEffect, useRef } from "react";
import { db } from "../firebase-config";
import { collection, getDocs, doc, setDoc, addDoc, deleteDoc, orderBy, query, where, limit, updateDoc, getDoc } from "firebase/firestore";
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

export default function AdminPanel() {
    const { currentUser } = useAuth()
    const modifica = useRef()
    const storage = getStorage()
    const [imgUpload, setImgUpload] = useState(null)
    const [imgLink, setImgLink] = useState(null)
    const [articoli, setBlog] = useState([])
    const [loading, setLoading] = useState(false)
    const [operazione, setOperazione] = useState("inserisci")
    const [ordini, setOrdini] = useState([]);
    const [categoriaSelected, setCategoriaSelected] = useState([])
    const [categorie, setCategorie] = useState([])

    const getBlog = async () => {
        const raccolta = await getDocs(query(
            collection(db, "blog"), orderBy("timestamp", "desc")));
        setBlog(raccolta.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    // GESTORE DELLE SPEDIZIONI
    const getOrdini = async () => {
        const raccolta = await getDocs(
            query(
                collection(db, "orders"),
                orderBy("timestamp", "desc"),
                limit(50)
            )
        );
        setOrdini(raccolta.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }

    const aggiornaStato = async (orderID) => {
        setLoading(true)
        if (document.getElementById("stato" + orderID).value != "-") {
            const modificaStato = async () => {
                await updateDoc(doc(db, "orders", orderID), {
                    paymentStatus: document.getElementById("stato" + orderID).value
                })
            }
            modificaStato()
            setOrdini([])
            getOrdini()
        }
        setLoading(false)
    }

    const getInfo = async () => {
        const informazioni = await getDoc(doc(db, "gestione", "infoBase"));
        setCategorie(informazioni.data()["categorieArticoli"])
    }

    useEffect(() => {
        getBlog();
        getOrdini()
        getInfo()
    }, [])

    const resetForm = () => {
        document.getElementsByName("inserimento")[0].reset();
        document.getElementById("imgProd").value = ""
        document.getElementById("imgProd").disabled = false
        setCategoriaSelected([])
        setOperazione("inserisci")
        setImgLink()
    }

    const modificaArticolo = (operazione, id, nome, descrizione, data, imgURL, categ) => {
        setOperazione(operazione)
        document.getElementById("nomeProd").value = nome
        document.getElementById("descrizioneProd").value = descrizione
        document.getElementById("IdArticle").value = id
        document.getElementById("data").value = data
        document.getElementById("imgProd").disabled = true
        document.getElementById("imgProd").value = ""
        setImgLink(imgURL)
        setCategoriaSelected(categ)
        modifica.current.scrollIntoView()
    }

    const AggiornaBlog = (e) => {
        e.preventDefault()
        setLoading(true)
        const dataOriginale = document.getElementById("data").value
        const dataFormatoUK = dataOriginale.substr(6, 9) + dataOriginale.substr(2, 5) + dataOriginale.substr(0, 1)
        const articolo_img_ref = ref(storage, 'blogImages/' + document.getElementById("nomeProd").value + '.jpg');
        const modificaArticolo = async (immagineCaricata) => {
            await setDoc(doc(db, "blog", document.getElementById("IdArticle").value), {
                nome: document.getElementById("nomeProd").value,
                descrizione: document.getElementById("descrizioneProd").value,
                imgURL: immagineCaricata,
                data: document.getElementById("data").value,
                timestamp: Math.floor(new Date(dataFormatoUK).getTime() / 1000),
                categorie: [...categoriaSelected]
            })
        }
        const inserisciArticolo = async (immagineCaricata) => {
            await addDoc(collection(db, "blog"), {
                nome: document.getElementById("nomeProd").value,
                descrizione: document.getElementById("descrizioneProd").value,
                imgURL: immagineCaricata,
                data: document.getElementById("data").value,
                timestamp: Math.floor(new Date(dataFormatoUK).getTime() / 1000),
                categorie: [...categoriaSelected]
            });
        }
        const eliminaArticolo = async () => {
            await deleteDoc(doc(db, "blog", document.getElementById("IdArticle").value));
            deleteObject(articolo_img_ref)
        }
        const prosegui = () => {
            window.scrollTo(0, 0)
            resetForm()
            getBlog()
            setLoading(false)
        }
        switch (operazione) {
            case "inserisci":
                uploadBytes(articolo_img_ref, imgUpload)
                    .then(snapshot => {
                        return getDownloadURL(snapshot.ref)
                    })
                    .then(downloadURL => {
                        inserisciArticolo(downloadURL)
                            .then(prosegui())
                    })
                break
            case "modifica":
                if (imgUpload) {
                    uploadBytes(articolo_img_ref, imgUpload)
                        .then(snapshot => {
                            return getDownloadURL(snapshot.ref)
                        })
                        .then(downloadURL => {
                            modificaArticolo(downloadURL)
                                .then(prosegui())
                        })
                }
                else {
                    modificaArticolo(imgLink)
                        .then(prosegui())
                }
                break
            case "elimina":
                eliminaArticolo()
                    .then(prosegui())
                break
        }
    }

    if (currentUser.uid == "0HSd2yvfLzSIuNQ6NWRLmkNIEXy1") {
        return (
            <div className="contenitoreVerticale">
                <div className="formCompatto">
                    <div className="contenitoreVerticale" id="orderHistory" style={{ height: "80%" }}>
                        <h1><ion-icon name="alarm-outline"></ion-icon> Gestore delle spedizioni</h1>
                        {ordini.length > 0 ? ordini.map((ordine) => {
                            var date = new Date(ordine.timestamp * 1000);

                            return (
                                <article key={ordine.id} className="prodotto" id="prodottoBig">
                                    <p>ID ORDINE: {ordine.id}
                                        <br /> ID UTENTE: {ordine.userId}
                                        <br /> Ordine del <b>{date.toLocaleDateString()},
                                            ore {date.toLocaleTimeString()}</b> |
                                        Totale: <b>€ {ordine.amountTotal / 100}</b> |
                                        Stato attuale: <b><u>{ordine.paymentStatus == "paid" ? "ORDINE EFFETTUATO" : ordine.paymentStatus}</u></b>
                                        <br />
                                        indirizzo: {
                                            ordine.shippingInfo["address"]["line1"] + ", "
                                            + (ordine.shippingInfo["address"]["line2"] == null ? "" : (ordine.shippingInfo["address"]["line2"] + ", "))
                                            + ordine.shippingInfo["address"]["city"] + ", "
                                            + ordine.shippingInfo["address"]["postal_code"]
                                        }
                                        <br />
                                        cliente: <b>{ordine.shippingInfo["name"]}</b>
                                    </p>
                                    <div className="contenitoreOrizzontale">
                                        <p>stato dell'ordine:</p>
                                        <td id="small" style={{ border: 0 }}>
                                            <select className="form" id={"stato" + ordine.id} disabled={loading}>
                                                <option>-</option>
                                                <option>ORDINE EFFETTUATO</option>
                                                <option>ORDINE SPEDITO</option>
                                                <option>ORDINE CONSEGNATO</option>
                                                <option>ORDINE RESTITUITO</option>
                                            </select>
                                        </td>
                                        <td id="small" style={{ border: 0 }}>
                                            <input type="button" value="aggiorna stato"
                                                disabled={loading} className="form" id="submit"
                                                onClick={() => aggiornaStato(ordine.id)}>
                                            </input>
                                        </td>
                                    </div>
                                </article>
                            )
                        })
                            : <h1 style={{ color: "grey" }}><br /> Ancora nessun cliente!</h1>}
                    </div>
                </div>
                <div className="formCompatto">
                    <div className="contenitoreVerticale" id="orderHistory">
                        <h1><ion-icon name="create-outline"></ion-icon> modifica articolo</h1>
                        <form id="small">
                            <table>
                                <tbody>
                                    <tr style={{ fontStyle: "italic" }}>
                                        <td>nome</td>
                                        <td>data</td>
                                        <td>timestamp</td>
                                    </tr>
                                    {articoli.map((articoloMappato) => {
                                        return (
                                            <tr key={articoloMappato.id}>
                                                <td style={{ overflow: "hidden" }}><b>{articoloMappato.nome}</b></td>
                                                <td>{articoloMappato.data}</td>
                                                <td>{articoloMappato.timestamp}</td>
                                                <td id="small" >
                                                    <input type="button" className="form" id="submit" value="modifica"
                                                        style={{ backgroundColor: "grey" }} disabled={loading}
                                                        onClick={() => modificaArticolo("modifica",
                                                            articoloMappato.id, articoloMappato.nome,
                                                            articoloMappato.descrizione,
                                                            articoloMappato.data,
                                                            articoloMappato.imgURL,
                                                            articoloMappato.categorie
                                                        )
                                                        } />
                                                </td>
                                                <td id="small">
                                                    <input type="button" className="form" id="submit" value="elimina"
                                                        disabled={loading} style={{ backgroundColor: "salmon" }}
                                                        onClick={() => modificaArticolo("elimina",
                                                            articoloMappato.id, articoloMappato.nome,
                                                            articoloMappato.descrizione,
                                                            articoloMappato.data
                                                        )
                                                        } />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </form>
                    </div>
                </div>
                <div className="formCompatto">
                    <div className="contenitoreVerticale">
                        <h1 ref={modifica}><ion-icon name="build-outline"></ion-icon> {operazione} articolo</h1>
                        <div className="contenitoreOrizzontale">
                            <form id="small" name="inserimento" onSubmit={AggiornaBlog}>
                                <div className="contenitoreOrizzontale" id="small">
                                    <input type="text" id="nomeProd" placeholder="titolo univoco" className="form" required />
                                    <input type="text" id="data" placeholder="GG/MM/AAAA" className="form" minLength="10" maxLength="10" required />
                                </div>
                                <div>
                                    <p>seleziona una o più categorie</p>
                                    <p> {JSON.stringify(categoriaSelected)} </p>
                                    {categoriaSelected? <ul className="filtra">
                                        {categorie.map((categoria, index) => {
                                            return (
                                                <li id={categoria} key={index}
                                                    className={categoriaSelected.includes(categoria) ? "categoriaActive" : "categoria"}
                                                    onClick={() => {
                                                        categoriaSelected.includes(categoria) ?
                                                            categoriaSelected = setCategoriaSelected(categoriaSelected.filter(e => e !== categoria))
                                                            : setCategoriaSelected([...categoriaSelected, categoria])
                                                    }}>
                                                    {categoria}
                                                    {categoriaSelected.includes(categoria) ?
                                                        <ion-icon name="close-circle-outline" /> : null}
                                                </li>
                                            )
                                        })}
                                    </ul> : null}
                                    </div>
                                    <br/>
                                    <div>
                                    <table style={{ border: "0" }}>
                                        <tbody style={{ border: "0" }}>
                                            <tr style={{ border: "0" }}>
                                                <td id="small" style={{ display: "flex", flexDirection: "row", border: "0", alignItems: "center" }}>
                                                    <h1 style={{ color: "gray" }}><ion-icon name="image-outline"></ion-icon></h1>
                                                    <input type="file" id="imgProd" className="form" required
                                                        onChange={(event) => {
                                                            setImgUpload(event.target.files[0])
                                                        }} />
                                                    {imgLink ? <a href={imgLink} target="_blank"> ▶ foto attuale </a> : null}
                                                    <input type="button" id="removePhoto" value="rimuovi foto" className="form"
                                                        disabled={operazione == "elimina" ? "disabled" : ""}
                                                        style={operazione == "elimina" ? { backgroundColor: "#B0B2B8", color: "grey" }
                                                            : { backgroundColor: "salmon", border: "0", color: "white", cursor: "pointer" }}
                                                        onClick={() => {
                                                            if (document.getElementById("removePhoto").disabled == false) {
                                                                document.getElementById("imgProd").value = "";
                                                                document.getElementById("imgProd").disabled = false
                                                                setImgLink(null)
                                                                setImgUpload(null)
                                                            }
                                                        }} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <textarea id="descrizioneProd" placeholder="corpo" className="form" style={{ width: "100%", fontWeight:"normal" }} required />
                                </div>
                                <div className="contenitoreOrizzontale" id="small">
                                    <input id="IdArticle" type="text" placeholder="ID (automatico)" className="form" disabled />
                                    <input name="submitProd" type="submit" className="form" id="submit"
                                        value={operazione} disabled={loading}
                                        style={operazione == "elimina" ? { width: "30%", backgroundColor: "salmon", fontWeight: "bold", color: "white" }
                                            : loading ? { backgroundColor: "#B0B2B8", color: "grey" }
                                                : { width: "30%", backgroundColor: "green", fontWeight: "bold", color: "white" }}></input>
                                    <input type="button" value="✖ svuota campi" className="form"
                                        onClick={() => resetForm()} />
                                </div>
                                <div>
                                    <p style={{ marginBottom: 0 }}> nota: il campo ID deve essere vuoto per inserire nuovi articoli </p>
                                    <p style={{ margin: 0 }}> nota: non inserire due articoli con stessa data </p>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
    else return (null)
}