import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useRef, useState } from "react";
import { sendEmailVerification } from "firebase/auth";

export default function PrivateRoute({ children }) {
  const { currentUser, login, logout, updatemail } = useAuth();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [mode, setMode] = useState("")
  const oldEmail = currentUser.email
  const newEmail = useRef()
  const pwAttuale = useRef()
  const userPw = currentUser.password

  async function handleEmailResend(e) {
    e.preventDefault()
    setError('')
    await sendEmailVerification(currentUser)
    setError("Email inviata")
    document.getElementById("emailResend").setAttribute("disabled", true)
    setLoading(false)
  }

  async function handleEmailUpdate(e) {
    e.preventDefault()
    setError('')
    if (oldEmail == newEmail.current.value) return setError("Email già in uso!")
    else {
      setLoading(true)
      const nuovaEmail = newEmail.current.value
      const passwordRichiesta = pwAttuale.current.value
      try {
        await updatemail(nuovaEmail, passwordRichiesta)
        if (currentUser.email == nuovaEmail) window.location.reload(true)
        else setError("Password non corretta")
      } catch {
        setError("Qualcosa è andato storto!")
      }
      setLoading(false)
    }
  }

  async function handleLogout(e) {
    setError('')
    setLoading(true)
    e.preventDefault()
    try {
      await logout()
      navigate("/")
      window.scrollTo({ top: 0, behavior: "instant" })
    } catch {
      setError("Qualcosa è andato storto durante il logout!")
    }
    setLoading(false)
  }

  switch (true) {
    case !currentUser:
      return (<Navigate to="/login" />)
      break
    case currentUser && !currentUser.emailVerified:
      return (
        <div className="contenitoreVerticale">
          <div className="formCompatto">
            <div className="contenitoreVerticale">
              <h1>La tua email non è stata ancora confermata!</h1>
              <h2>Controlla la tua casella di posta. <br />
                (<u style={{ cursor: "pointer" }} onClick={() => window.location.reload(true)}>
                  Aggiorna la pagina
                </u> se hai già cliccato sul link di conferma)
              </h2>
              <hr />
              <form onSubmit={handleEmailResend}>
                <input type="submit" className="form" disabled={loading}
                  value={mode == "resend" ? "➡️ Invia di nuovo" : "Invia di nuovo"}
                  style={{ backgroundColor: "silver", cursor: "pointer" }}
                  onClick={() => setMode("resend")}
                  id="emailResend"
                >
                </input>
              </form>
              <p>oppure</p>
              <form onSubmit={handleEmailUpdate}>
                <input type="button" className="form" disabled={loading}
                  value={mode == "update" ? "➡️ modifica email" : "modifica email"}
                  style={{ backgroundColor: "silver", cursor: "pointer" }}
                  onClick={() => setMode("update")}>
                </input>
                {mode == "update" ?
                  <div>
                    <input type="email" ref={newEmail} className="form" placeholder="nuova email" disabled={loading}></input>
                    <input ref={pwAttuale} type="password" className="form" placeholder="password" required></input>
                    <input type="submit" className="form" id="submit" value="modifica" disabled={loading}></input>
                  </div>
                  :
                  null
                }
              </form>

              {error &&
                <h2 style={{ display: "flex", alignItems: "center" }}> {error} </h2>
              }
            </div>
          </div>
          <form onSubmit={handleLogout} className="contenitoreVerticale">
            <input type="submit" className="form" id="submit" value="Esci" disabled={loading}
              style={{ backgroundColor: "salmon", cursor: "pointer" }}></input>
          </form>
        </div>
      )
      break
    case currentUser && currentUser.emailVerified:
      return (children)
      break
  }

  return currentUser && currentUser.emailVerified ? children : <Navigate to="/login" />;
}