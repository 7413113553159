import "./css/App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home"
import Catalogo from "./pages/Catalogo"
import About from "./pages/About"
import Navbar from "./components/Navbar"
import Login from "./components/Login";
import Signup from "./components/Signup";
import { AuthProvider } from "./contexts/AuthContext";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./components/PrivateRoute";
import PrivateRouteLogged from "./components/PrivateRouteLogged";
import Forgot from "./components/Forgot"
import Carrello from "./pages/Carrello";
import { CartProvider } from "./contexts/CartContext";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase-config";
import ReactGA from 'react-ga4';
import Cookies from "js-cookie";

const TRACKING_ID = "G-BX94VV2GWR"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [linkPrivacy, setPrivacy] = useState([""])
  const accettaCookie = () => {
    // Imposta un cookie, ad esempio con il nome "cookieAccettato", con una scadenza appropriata
    Cookies.set('cookieAccettato', 'true', { expires: 7 })
    document.getElementById("cookie-banner").style.display = "none";
  }

  useEffect(() => {
    const getInfo = async () => {
      const informazioni = await getDoc(doc(db, "gestione", "infoBase"));
      setPrivacy(informazioni.data()["linkPrivacy"])
    }
    getInfo();
    // controlla se cookie accettati
  }, [])

  useEffect(() => {
    ReactGA.send("pageview")
  }, [])

  useEffect(() => {
    const checkCookieAndHideBanner = () => {
      if (Cookies.get()["cookieAccettato"] == "true") {
        const banner = document.getElementById("cookie-banner")
        if (banner) banner.style.display = "none";
      }
      else {
        const banner = document.getElementById("cookie-banner")
        if (banner) banner.style.display = "block";
      }
    };
    checkCookieAndHideBanner();
  }, [linkPrivacy])

  return (
    <AuthProvider>
      <CartProvider>
        <div className="contenitoreVerticale">
          <Navbar />
          <div className="contenuto">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/:articolo" element={<Home preview="true" />} />
              <Route path="/Catalogo" element={<Catalogo />} />
              <Route path="/Catalogo/:prodotto" element={<Catalogo preview="true" />} />
              <Route path="/About" element={<About />} />
              <Route path="/cart" element={<Carrello />} />
              <Route path="/login"
                element={
                  <PrivateRouteLogged>
                    <Login />
                  </PrivateRouteLogged>} />
              <Route path="/signup"
                element={
                  <PrivateRouteLogged>
                    <Signup />
                  </PrivateRouteLogged>} />
              <Route path="/forgotpassword"
                element={
                  <PrivateRouteLogged>
                    <Forgot />
                  </PrivateRouteLogged>} />
              <Route path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>} />
            </Routes>
          </div>
          <br />
          <div id="cookie-banner">
            <p>Questo sito usa cookie di Google Analytics per raccogliere dati in forma aggregata e anonima.
              Per ogni ulteriore informazione ti invitiamo a consultare <a href={linkPrivacy} target="_blank">la nostra policy</a> ed
              eventualmente a contattarci all'indirizzo privacy@greylovestore.com
            </p>
            <button onClick={accettaCookie}>Accetta</button>
          </div>
          <footer className="footer">
            Vuoi saperne di più su di noi? Visita la sezione F.A.Q.
            oppure contattaci sui nostri social o via email:
            <div className="contenitoreOrizzontale">
              <a href="https://www.instagram.com/greylovestore/" target="_blank" style={{ display: "flex", color: "grey", alignItems: "center" }}>
                <ion-icon name="logo-instagram" id="footer_icon"></ion-icon>
              </a>
              <a href="https://www.facebook.com/greylovestore/" target="_blank" style={{ display: "flex", color: "grey", alignItems: "center" }}>
                <ion-icon name="logo-facebook" id="footer_icon"></ion-icon>
              </a>
              <a href="mailto:info@greylovestore.com" style={{ display: "flex", color: "grey", alignItems: "center" }}>
                <ion-icon name="mail-outline" id="footer_icon"></ion-icon>
              </a>
            </div>
          </footer>
          <footer className="footer">
            © 2023 Ego21 srls, via Don Murri 12, Cassano delle Murge (BA),
            N° REA BA-650748, Cap. Soc. € 2.000 I.V.,
            CF e P.IVA 08798210723 - info@greylovestore.com
          </footer>
          <br />
        </div>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;
