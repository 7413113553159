import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function Login() {
    const emailRef = useRef()
    const pwRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e){
        setError('')
        setLoading(true)
        e.preventDefault()
        try{
            await login(emailRef.current.value, pwRef.current.value)
            navigate("/dashboard")
        } catch {
            setError("Qualcosa è andato storto!")
        }
        setLoading(false)
    } 

    return(
        <form onSubmit={handleSubmit} className="contenitoreVerticale">
            <h1> Login </h1>
            {error &&
            <h2 style={{display:"flex", alignItems:"center"}}> <ion-icon name="alert-circle-outline"></ion-icon> {error} </h2>}
            <input type="email" ref={emailRef} className="form" placeholder="email" required></input>
            <input type="password" ref={pwRef} className="form" placeholder="password" required></input>
            <input type="submit" className="form" id="submit" value="Entra" disabled={loading}></input>
            <p style={{textAlign:"center"}}> Non hai un account? <Link to="/signup">Registrati</Link>. <br/> 
            Se hai dimenticato la password, <Link to="/forgotpassword">clicca qui</Link>.</p> 
        </form>
    )
}