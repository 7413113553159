import { useEffect, useRef, useState } from "react";
import Articolo from "../components/Articolo";
import Blog from "../components/Blog";
import { db } from "../firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { Link } from "react-router-dom";

export default function Home(props) {
    const immagini = [
        "https://firebasestorage.googleapis.com/v0/b/grey-love.appspot.com/o/homeImages%2F1.jpg?alt=media&token=fb43c840-51eb-44d9-838c-364d3cbc8243",
        "https://firebasestorage.googleapis.com/v0/b/grey-love.appspot.com/o/homeImages%2F2.jpg?alt=media&token=d8030770-b316-4146-8e5f-7ecffaa391b0",
        "https://firebasestorage.googleapis.com/v0/b/grey-love.appspot.com/o/homeImages%2F3.jpg?alt=media&token=f40ad793-4292-4660-bbce-bb5900991410",
        "https://firebasestorage.googleapis.com/v0/b/grey-love.appspot.com/o/homeImages%2F4.jpg?alt=media&token=9e28b618-bc93-4e78-92a4-e4985cf53c0c",
        "https://firebasestorage.googleapis.com/v0/b/grey-love.appspot.com/o/homeImages%2F5.jpg?alt=media&token=1efed30b-910a-470d-94ff-c6a335bcffd9",
        "https://firebasestorage.googleapis.com/v0/b/grey-love.appspot.com/o/homeImages%2F6.jpg?alt=media&token=61aaf8df-3a6d-4b18-9dbb-8138e4c4a5af",
        "https://firebasestorage.googleapis.com/v0/b/grey-love.appspot.com/o/homeImages%2F7.jpg?alt=media&token=239f8757-8836-4998-88e6-cf0d0b1ec34b",
        "https://firebasestorage.googleapis.com/v0/b/grey-love.appspot.com/o/homeImages%2F8.jpg?alt=media&token=4c507154-1fb7-44de-9bd4-d64e793497f7",
    ]
    const [header, setHeader] = useState()
    const [subheader, setSubheader] = useState()
    const [caption1, setCaption1] = useState()
    const [caption2, setCaption2] = useState()
    const us = useRef()
    const blog = useRef()

    useEffect(() => {
        const getInfo = async () => {
            const informazioni = await getDoc(doc(db, "gestione", "infoBase"));
            setHeader(informazioni.data()["homeHeader"])
            setSubheader(informazioni.data()["homeSubheader"])
            setCaption1(informazioni.data()["homeCaption1"])
            setCaption2(informazioni.data()["homeCaption2"])
        }
        getInfo()
    }, [])

    return (
        <div className="contenitoreVerticale">
            {props.preview ?
                <Articolo />
                :
                <div className="contenitoreVerticale">
                    <div style={{ overflow: "hidden" }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/grey-love.appspot.com/o/homeImages%2FalertPhoto.png?alt=media&token=9fc09c5b-c2c7-4a10-9535-b67c623bb9a5"
                            loading="lazy" className="homeImage" id="headerImage" />
                    </div>
                    <h1>{header}</h1>
                    <br className="hideOnDesktop" />
                    <div className="contenitoreOrizzontale">
                        <Link to="/catalogo" style={{ textDecoration: "none"}}
                            onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}>
                            <input type="button" style={{ backgroundColor: "#E3BAC6" }}
                                className="form" id="submit" value="CATALOGO ↗️"></input>
                        </Link>
                        <input type="button" style={{ backgroundColor: "#BC9EC1" }}
                            onClick={() => us.current.scrollIntoView({ behavior: "smooth" })}
                            className="form" id="submit" value="SU DI NOI ⬇️"></input>
                        <input type="button" style={{ backgroundColor: "#ADA7C9" }}
                            onClick={() => blog.current.scrollIntoView({ behavior: "smooth" })}
                            className="form" id="submit" value="IL NOSTRO BLOG ⬇️"></input>
                    </div>
                    <h1 className="header">{subheader}</h1>
                    <div style={{ overflow: "hidden" }}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/grey-love.appspot.com/o/homeImages%2FmainPhoto.png?alt=media&token=a1238a19-da01-4b9f-a414-17cc4554da31"
                            loading="lazy" className="homeImage" id="homeImage" />
                    </div>
                    <h1 ref={us}>♡</h1>
                    <h1 className="header" style={{ textAlign: "justify" }}>{caption1}</h1>
                    <h1 className="header" style={{ textAlign: "justify" }}>{caption2}</h1>
                    <br />
                    <div className="contenitoreOrizzontale">
                        <Link to="/catalogo" style={{ textDecoration: "none" }}
                            onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}>
                            <input type="button" style={{ backgroundColor: "silver" }}
                                className="form" id="submit" value="CATALOGO ↗️"></input>
                        </Link>
                    </div>
                    <br />
                    <div className="grid" style={{ backgroundColor: "transparent" }}>
                        {immagini.map((immagine) => {
                            return (
                                <img src={immagine} loading="lazy"
                                    key={immagine} alt="" style={{ border: 0, borderRadius: "100vw" }} />
                            )
                        })}
                    </div>
                    <br /><br /><br ref={blog} />
                    <Blog />
                </div>
            }
            <br />
        </div>
    );
}