import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase-config";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { arrotonda } from "..";

export default function Preview(props) {
    const params = useParams()
    const navigate = useNavigate()
    const cart = useContext(CartContext)
    const [idProdotto, setIdProdotto] = useState();
    const [prodotto, setProdotto] = useState();
    const [immagini, setImmagini] = useState([])
    const [main, setMain] = useState()
    const [correlati, setCorrelati] = useState([])

    useEffect(() => {
        setIdProdotto(params.prodotto)
    })

    useEffect(() => {
        const getProdotto = async () => {
            const docSnap = await getDocs(query(collection(db, "sexyshopingrosso"),
                where("ID prodotto + ID combinazione", "==", idProdotto)))
            try {
                //setProdotto(docSnap.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
                setProdotto(docSnap.docs.map((doc) => ({ ...doc.data() }))[0])
            } catch {
                console.log("Prodotto non trovato.");
            }
        }
        if (idProdotto) getProdotto()
    }, [idProdotto])

    useEffect(() => {
        if (prodotto) {
            setImmagini(prodotto["URL immagini"].split(","))
        }
        setCorrelati([])
        const getCorrelati = async () => {
            const raccolta = await getDocs(
                query(
                    collection(db, "sexyshopingrosso"),
                    where("Nome prodotto", ">=", prodotto["Nome prodotto"]),
                    orderBy("Nome prodotto"),
                    limit(8)
                )
            )
            const mappatura = raccolta.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            if (mappatura.length != 0) setCorrelati(correlati.concat(mappatura));
        }
        if (prodotto) getCorrelati()
    }, [prodotto])

    useEffect(() => {
        setMain(immagini[0])
    }, [immagini])

    return (
        <div className="contenitoreVerticale" style={{ display: props.display }} id="preview">
            <div className="pages" style={{ textDecoration: "none" }}
                onClick={() => { setIdProdotto(""); setImmagini([]); setProdotto(); navigate(-1) }}>
                <ion-icon name="backspace-outline" />
                <p>indietro</p>
            </div>
            {prodotto ?
                <article key={prodotto["id"]} className="prodotto" id="prodottoBig">
                    <div className="image">
                        <img src={main} loading="lazy" alt="" />
                        {immagini.length > 1 ?
                            <div>
                                <div className="grid">
                                    {immagini.map((immagine) => {
                                        return (
                                            <img src={immagine} loading="lazy"
                                                key={immagine} alt=""
                                                onClick={() => setMain(immagine)} />
                                        )
                                    })}
                                </div>
                            </div> : null}
                    </div>
                    <br />
                    <div className="articolo" style={{ cursor: "auto" }}>
                        <h1 style={{ marginBottom: "0" }}>
                            {prodotto["Nome prodotto"]}
                        </h1>
                        {prodotto["Nome attributo"] ?
                            <p>Taglia:  {prodotto["Valore attributo"]}</p>
                            : null}
                        <p> {"Brand: " + prodotto["Brand"]} </p>
                        <p>Descrizione: {prodotto["Descrizione"]
                            .replaceAll(/<\/?[^>]+(>|$)/g, "")}</p>
                        <p style={{ marginBottom: "0", fontWeight: "bold" }}>Scheda tecnica</p>
                        <p style={{ marginTop: "0" }}>{(prodotto["Caratteristiche"] + ";")
                            .replaceAll(/:+[0-9];/g, " | ").replaceAll(": :", ":").replaceAll(":", ": ")}
                        </p>
                        <br />
                        <div className="price">
                            <h2>€ {arrotonda(prodotto["Prezzo (consigliato)"].toFixed(2))}</h2>
                            <button onClick={() => { cart.addToCart(prodotto["ID prodotto + ID combinazione"]) }}
                                className="addToCart" value="aggiungi al carrello">
                                <ion-icon name="bag-add-outline"></ion-icon> Aggiungi
                            </button>
                        </div>
                    </div>
                </article>
                :
                null
            }
            <div className="contenitoreOrizzontale">
                <p>potrebbero interessarti:</p>
            </div>
            <div className="contenitoreOrizzontale">
                {correlati.map((prodotto) => {
                    return (
                        <article key={prodotto["id"]} className="prodotto">
                            <Link to={"/Catalogo/" + prodotto["ID prodotto + ID combinazione"]}
                                className="image" onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}>
                                <img src={prodotto["URL immagini"].split(",")[0]} loading="lazy" />
                            </Link>
                            <Link to={"/Catalogo/" + prodotto["ID prodotto + ID combinazione"]}
                                style={{ textDecoration: "none" }} onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}>
                                <h1 className="seeMore">
                                    {prodotto["Nome prodotto"]}
                                </h1>
                            </Link>
                            <p style={{ margin: "0" }}> {prodotto["Nome attributo"] ?
                                "Taglia" + ": " + prodotto["Valore attributo"]
                                : null}
                            </p>
                            <div className="price">
                                <h3>€ {arrotonda(prodotto["Prezzo (consigliato)"].toFixed(2))}</h3>
                                <button onClick={() => { cart.addToCart(prodotto["ID prodotto + ID combinazione"]) }}
                                    className="addToCart" value="aggiungi al carrello">
                                    <ion-icon name="bag-add-outline"></ion-icon> Aggiungi
                                </button>
                            </div>
                        </article>
                    )
                })}
            </div>
            {prodotto ? <div className="pages" style={{ textDecoration: "none" }}
                onClick={() => { navigate(-1); setIdProdotto(""); setImmagini([]); setProdotto() }}>
                <ion-icon name="backspace-outline" />
                <p>indietro</p>
            </div> : null}
        </div >
    )
}