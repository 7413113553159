import { doc, getDoc, setDoc } from "firebase/firestore"
import React, { useEffect, useState } from "react"
import { auth, db } from "../firebase-config"
import { useAuth } from "./AuthContext"
import { onAuthStateChanged } from "firebase/auth"

export const CartContext = React.createContext({
    items: [],
    getQuantity: () => { },
    addToCart: () => { },
    removeFromCart: () => { },
    getTotal: () => { }
})

export function CartProvider({ children }) {
    /*
    const localCart = currentUser? [] : JSON.parse(localStorage.getItem("carrello")) || []
    const [cartProducts, setProducts] = useState(localCart)
    useEffect(() => {
        if (!currentUser) localStorage.setItem("carrello", JSON.stringify(cartProducts))
    }, [cartProducts])
    */

    const { currentUser } = useAuth()
    const [cartProducts, setProducts] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (loading && currentUser) {
            const getDbCart = async () => {
                const docRef = doc(db, "carrello", currentUser.uid);
                const docSnap = await getDoc(docRef);
                //dbCart è il nome del campo su firebase -> dbCart : [...]
                try { setProducts(JSON.parse(docSnap.data().dbCart)) }
                catch { }
                setLoading(false)
            };
            getDbCart()
        }
        else if (currentUser && loading == false) {
            const updateDbCart = async () => {
                await setDoc(doc(db, "carrello", currentUser.uid), { dbCart: JSON.stringify(cartProducts) })
            }
            updateDbCart();
        }
        if (loading && !currentUser) {
            const localCart = JSON.parse(localStorage.getItem("carrello"))
            setLoading(false)
        }
        else if (!currentUser && loading == false) {localStorage.setItem("carrello", JSON.stringify(cartProducts))}
    })

    useEffect(() => {
        if (!currentUser) setProducts(JSON.parse(localStorage.getItem("carrello")) || [])
        onAuthStateChanged(auth, currentUser => setLoading(true))
    }, [currentUser])

    function getQuantity(id) {
        const quantity = cartProducts.find(product => product.id === id)?.quantity
        if (quantity === undefined) return 0
        return quantity
    }

    async function addToCart(id) {
        const quantity = getQuantity(id)
        if (quantity == 0) {
            setProducts(
                [...cartProducts,
                { id: id, quantity: 1 }]
            )
        } else {
            setProducts(
                cartProducts.map(
                    product => product.id == id
                        ? { ...product, quantity: product.quantity + 1 }
                        : product
                )
            )
        }
    }

    function removeFromCart(id) {
        const quantity = getQuantity(id)
        if (quantity == 1) {
            setProducts(cartProducts =>
                cartProducts.filter(currentProduct => {
                    return currentProduct.id != id
                })
            )
        } else {
            setProducts(
                cartProducts.map(
                    product => product.id === id
                        ? { ...product, quantity: product.quantity - 1 }
                        : product
                )
            )
        }
    }

    const contextValue = {
        items: cartProducts,
        getQuantity,
        addToCart,
        removeFromCart
    }

    return (
        < CartContext.Provider value={contextValue} >
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider