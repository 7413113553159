import { EmailAuthProvider, createUserWithEmailAndPassword, reauthenticateWithCredential, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateEmail, updatePassword } from "firebase/auth"
import React, { useContext, useEffect, useState } from "react"
import { auth } from "../firebase-config.js"

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    const signup = async (email, password) => {
        try {
            // Registra l'utente utilizzando email e password
            const { user } = await createUserWithEmailAndPassword(auth, email, password);
        
            // Invia l'email di verifica
            await sendEmailVerification(user);
        
            // Esegui altre azioni dopo la registrazione
            // ...
          } catch (error) {
            console.error("Errore durante la registrazione:", error);
            // Gestisci l'errore di registrazione
          }
    }

const login = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
}

const logout = async () => {
    return signOut(auth)
}

const resetPw = async (email) => {
    return sendPasswordResetEmail(auth, email)
}

const updatemail = async (newemail, password) => {
    try {
        // verifica correttezza password
        const credential = EmailAuthProvider.credential(
            currentUser.email,
            password
          );
          try {
            await reauthenticateWithCredential(currentUser, credential);
            console.log('La password è corretta');
            await updateEmail(currentUser, newemail);
            await sendEmailVerification(currentUser);
            const newCredential = EmailAuthProvider.credential(
                newemail,
                password
              );
            await reauthenticateWithCredential(currentUser, newCredential);
          } catch (error) {
            console.log('La password non è corretta');
          }

      } catch (error) {
        console.error("Errore durante la modifica dell'email:", error);
      }
}

const updatepw = async (newpassword) => {
    return updatePassword(currentUser, newpassword)
}

useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
        setCurrentUser(user)
        setLoading(false)
    })
    return unsubscribe
}, [])

const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPw,
    updatemail,
    updatepw
}

return (
    <AuthContext.Provider value={value}>
        {!loading && children}
    </AuthContext.Provider>
)
}