import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth"
import { getFunctions, httpsCallable } from 'firebase/functions';
import { loadStripe } from "@stripe/stripe-js";
import { arrotonda } from "./index.js"

const firebaseConfig = {
  apiKey: "AIzaSyCcrDksKU0t2AT2IECgMAhow56ykKrxDOU",
  authDomain: "grey-love.firebaseapp.com",
  projectId: "grey-love",
  storageBucket: "grey-love.appspot.com",
  messagingSenderId: "254996371751",
  appId: "1:254996371751:web:00bec7d36d810e58f3b102",
  measurementId: "G-83HV44ZB72"
};

const app = initializeApp(firebaseConfig);

// CHIAMATA STRIPE API
const functions = getFunctions(app);
const createStripeCheckout = httpsCallable(functions, "createStripeCheckout")
export const eseguiCheckout = async (userCart, productData, spedizione, user_id) => {
  // firebase functions:config:set stripe.secret_key=(...)
  // MODALITA LIVE
  // const stripe = await loadStripe("pk_live_51MIBorBMNlgaQ6pgVdb3fxf8cP9EB140hPTe69uJLeauZ4jJ94UKKrfzFyQW7wKOBfgu4Zj9PkD5rGENeOLw59L600rsXg9Wwv")
  // MODALITA TEST
  const stripe = await loadStripe("pk_live_51MIBorBMNlgaQ6pgVdb3fxf8cP9EB140hPTe69uJLeauZ4jJ94UKKrfzFyQW7wKOBfgu4Zj9PkD5rGENeOLw59L600rsXg9Wwv")
  const carrelloRicevuto = userCart.map((currentProduct) => {
    const container = {}
    container.quantita = currentProduct.quantity;
    container.prezzo = Math.round(arrotonda((productData?.[currentProduct.id]?.["Prezzo (consigliato)"]).toFixed(2)) * 100) / 100;
    container.nome = productData?.[currentProduct.id]?.["Nome prodotto"];
    container.immagine = [productData?.[currentProduct.id]?.["URL immagini"]]
    if (productData?.[currentProduct.id]?.["Valore attributo"])
      container.descrizione = "Taglia: " + productData?.[currentProduct.id]?.["Valore attributo"]
    return container
  })
  var myData = {
    successo: "http://greylovestore.com/#/dashboard",
    cancella: "http://greylovestore.com/#/cart",
    nazioni: ["IT"],
    stima_min: 5,
    stima_max: 7,
    shipping_cost: spedizione,
    carrello: carrelloRicevuto,
    mieiMetadata: {
      userId: user_id,
    }
  }

  createStripeCheckout(myData)
    .then(response => {
      const sessionId = response.data.id
      stripe.redirectToCheckout({ sessionId: sessionId })
    })
}

export const db = getFirestore(app);
export const auth = getAuth(app);