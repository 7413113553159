import "../css/Navbar.css";
import { NavLink } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useContext } from "react";
import { CartContext } from "../contexts/CartContext";

function Navbar() {
    const { currentUser } = useAuth()
    const cart = useContext(CartContext)
    const productCount = cart.items.reduce((sum, product) => sum + product.quantity, 0)
    return (
        <ul className="navigazione">
            <div className="hideOnMobile">
                <li className="Link">
                    <img className="logo_img" src="%PUBLIC_URL%/../greylove.png" />
                </li>
            </div>
            <NavLink className="Link" activeclassname="active" to="/">
                <ion-icon name="heart-circle-outline" id="nav_icon" /> HOME
            </NavLink>
            <NavLink className="Link" activeclassname="active" to="/catalogo">
                <ion-icon name="star-outline" id="nav_icon" /> CATALOGO
            </NavLink>
            <NavLink className="Link" activeclassname="active" to="/about">
                <ion-icon name="happy-outline" id="nav_icon" /> F.A.Q.
            </NavLink>
            <NavLink className="Link" activeclassname="active"
                to={currentUser ? "/dashboard" : "/login"}>
                <ion-icon name="person-circle-outline" id="nav_icon" /> {currentUser ? currentUser.email.split("@")[0] : "Accedi"}
            </NavLink>
            <NavLink className="Link" activeclassname="active" to="/cart">
                <ion-icon name="cart-outline" id="nav_icon"></ion-icon> ({productCount})
            </NavLink>
        </ul>
    );
}

export default Navbar;