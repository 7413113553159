import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function Signup() {
    const emailRef = useRef()
    const { resetPw } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e){
        setError('')
        setLoading(true)
        e.preventDefault()
        try{
            await resetPw(emailRef.current.value)
            navigate("/login")
        } catch {
            setError("Nessun account è associato a questa email!")
        }
        setLoading(false)
    } 

    return(
        <form onSubmit={handleSubmit} className="contenitoreVerticale">
            <h1> Recupera password </h1>
            <p> Ti invieremo una mail con le istruzioni per resettarla.</p>
            {error && 
            <h2 style={{display:"flex", alignItems:"center"}}> <ion-icon name="alert-circle-outline"></ion-icon> {error} </h2>}
            <input type="email" ref={emailRef} className="form" placeholder="email" required></input>
            <input type="submit" className="form" id="submit" value="Invia email" disabled={loading}></input>
            <p style={{textAlign:"center"}}> Oppure torna al <Link to="/login">login</Link>. <br/>
            Non hai un account? <Link to="/signup">Registrati</Link>.</p> 
        </form>
    )
}