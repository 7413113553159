import "../css/generale.css";
import "../css/Prodotto.css";
import { useState, useEffect, useContext, useRef } from "react";
import { db } from "../firebase-config";
import { collection, doc, getDoc, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { CartContext } from "../contexts/CartContext";
import { Link } from "react-router-dom";
import { arrotonda } from "../index.js"

export default function Prodotti(props) {
    const dbScelto = "sexyshopingrosso"
    const top = useRef()
    const cart = useContext(CartContext)
    const [categoriaDefault, setCategoriaDefault] = useState("")
    const [index, setIndex] = useState({ a: 0, b: 0 })
    const [prodotti, setProdotti] = useState([]);
    const [filtro, setFiltro] = useState("Categoria")
    const [operatore, setOperatore] = useState("array-contains")
    const [categoriaSelected, setCategoriaSelected] = useState(categoriaDefault)
    const [full, setFull] = useState(false)
    const [first, setFirst] = useState(query(
        collection(db, dbScelto),
        where(filtro, operatore, categoriaSelected),
        orderBy(filtro),
        limit(20)
    ), [])

    useEffect(() => {
        setProdotti([])
        setFull(false)
        setIndex(({
            a: 0,
            b: 0
        }));
        setFirst(query(
            collection(db, dbScelto),
            where(filtro, operatore, categoriaSelected),
            orderBy(filtro),
            limit(20)
        ))
    }, [categoriaSelected])

    useEffect(() => {
        const getProdotti = async () => {
            const raccolta = await getDocs(first);
            const mappatura = raccolta.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            if (mappatura.length != 0) setProdotti(prodotti.concat(mappatura));
        }
        getProdotti();
    }, [first])

    // ottengo la porzione successiva della raccolta
    const Refresh = (dove) => {
        switch (dove) {
            case "previous":
                if (index.a > 19) {
                    setIndex(prevState => ({
                        //b-a = larghezza intervallo
                        ...prevState,
                        a: prevState.a - 20,
                        b: prevState.b - (prevState.b - prevState.a) - 1
                    }));
                }
                break
            case "next":
                if (index.b == prodotti.length - 1 && !full) {
                    var lastVisible = prodotti[prodotti.length - 1]
                    const next = query(
                        collection(db, dbScelto),
                        where(filtro, operatore, categoriaSelected),
                        orderBy(filtro),
                        startAfter(lastVisible[filtro]),
                        limit(20)
                    )
                    const getProdotti = async () => {
                        const raccolta = await getDocs(next);
                        const mappatura = raccolta.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                        setProdotti(prodotti.concat(mappatura))
                        setIndex(prevState => ({
                            ...prevState,
                            //b-a = larghezza intervallo
                            a: prevState.a + prevState.b - prevState.a + 1
                        }));
                    }
                    getProdotti();
                    //l'index b si aggiorna grazie all'useEffect che rileva l'aggiunta di prodotti
                }
                else if (!full || full && index.b < prodotti.length - 1) {
                    // caso in cui rimangono meno elementi dell'intervallo standard (ultima pagina)
                    if (prodotti.length - index.b < index.b - index.a) {
                        setIndex(prevState => ({
                            ...prevState,
                            a: prevState.a + (prevState.b - prevState.a) + 1,
                            b: prevState.b + (prodotti.length - prevState.b) - 1
                        }));
                    }
                    // caso standard in cui la prossima pagina non è l'ultima
                    else setIndex(prevState => ({
                        ...prevState,
                        a: prevState.a + (prevState.b - prevState.a) + 1,
                        b: prevState.b + (prevState.b - prevState.a) + 1
                    }));
                }
                break
        }
    }

    useEffect(() => {
        setIndex(prevState => ({
            ...prevState,
            b: prodotti.length - 1
        }))
        // CONTROLLO SE HO ALMENO UN ELEMENTO NON ANCORA MOSTRATO
        const getProdotti = async () => {
            var lastVisible = prodotti[prodotti.length - 1]
            const check = query(
                collection(db, dbScelto),
                where(filtro, operatore, categoriaSelected),
                orderBy(filtro),
                startAfter(lastVisible[filtro]),
                limit(1)
            )
            const documentoProva = await getDocs(check);
            const mappaturaProva = documentoProva.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            if (mappaturaProva.length == 0) setFull(true)
        }
        if (categoriaSelected && prodotti[prodotti.length - 1]) getProdotti();
    }, [prodotti])

    // FILTRA PER CATEGORIA, A-Z, FASCIA PREZZO
    const [Categoria, setCategoria] = useState([])
    const [fascePrezzo, setFascePrezzo] = useState([])
    const [header, setHeader] = useState([])
    useEffect(() => {
        const getInfo = async () => {
            const informazioni = await getDoc(doc(db, "gestione", "infoBase"))
            setCategoria(informazioni.data()["categorie"])
            setFascePrezzo(informazioni.data()["fascePrezzo"])
            setCategoriaDefault(informazioni.data()["categoriaDefault"])
        }
        getInfo()
    }, [])
    useEffect(() => {
        Categoria.sort()
        setTipoFiltro(Categoria)
        setCategoriaSelected(categoriaDefault)
    }, [Categoria])
    const AZ = [
        "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O",
        "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
    const [tipoFiltro, setTipoFiltro] = useState(Categoria)
    const cambiaFiltro = () => {
        if (document.getElementById("tipoFiltro").value == "Categoria") {
            setTipoFiltro(Categoria)
            setFiltro("Categoria")
            setOperatore("array-contains")
        }
        else if (document.getElementById("tipoFiltro").value == "A-Z") {
            setTipoFiltro(AZ)
            setFiltro("Nome prodotto")
            setOperatore(">=")
        }
        else if (document.getElementById("tipoFiltro").value == "Prezzo") {
            setTipoFiltro(fascePrezzo)
            setFiltro("Prezzo (consigliato)")
            setOperatore(">=")
        }
    }

    return (
        <div className="contenitoreVerticale" style={{ display: props.display }}>
            <div style={{ overflow: "hidden" }}>
                <img src="https://firebasestorage.googleapis.com/v0/b/grey-love.appspot.com/o/homeImages%2FalertPhoto.png?alt=media&token=9fc09c5b-c2c7-4a10-9535-b67c623bb9a5"
                    loading="lazy" className="homeImage" id="headerImage" />
            </div>
            <div>
                <div className="pages">
                    <p className="hideOnMobile">cerca ciò che fa al caso tuo</p>
                    <ion-icon name="search"></ion-icon>
                    <input type="text" placeholder="cerca articolo" id="CERCA"
                        style={{ textAlign: "center", borderRadius: "1vw", border: "1.5px solid darkgrey" }} />
                    <input type="button" id="CERCA_BUTTON"
                        value="vedi risultati" className="categoria"
                        style={{ margin: "0", border: "1.5px solid darkgrey" }}
                        onClick={() => {
                            setTipoFiltro(AZ)
                            setFiltro("Nome prodotto")
                            setOperatore(">=")
                            document.getElementById("tipoFiltro").value = "A-Z"
                            if (document.getElementById("CERCA").value != "")
                                setCategoriaSelected(
                                    document.getElementById("CERCA").value
                                        .slice(0, 1)
                                        .toUpperCase()
                                    + document.getElementById("CERCA").value
                                        .slice(1)
                                        .toLowerCase()
                                );
                        }} />
                </div>
            </div>
            <br className="hideOnDesktop"/>
            <ul className="filtra">
                <li style={{ color: "grey" }}
                    className="hideOnMobile"> <ion-icon name="gift-outline" /> Filtra per
                </li>
                <select id="tipoFiltro" className="categoria"
                    style={{ margin: "0", border: "1.5px solid darkgrey" }}
                    onChange={() => cambiaFiltro()}>
                    <option value="Categoria">Categoria</option>
                    <option value="A-Z"> A-Z </option>
                    <option value="Prezzo"> Prezzo </option>
                </select>
                <li id="ESPLORA" style={{ color: "grey" }}
                    className="categoriaSelector"> :
                </li>
                {tipoFiltro.map((categoria, index) => {
                    return (
                        <li style={{backgroundColor: "#BC9EC1"}}
                            id={categoria} key={index}
                            className={categoriaSelected == categoria ? "categoriaActive" : "categoria"}
                            onClick={() => {
                                categoriaSelected == categoria ?
                                    setCategoriaSelected(categoriaDefault)
                                    : setCategoriaSelected(categoria)
                            }}>
                            {categoriaSelected == categoria ?
                                <ion-icon name="close-circle-outline" /> : null}
                            {filtro == "Prezzo (consigliato)" ?
                                <p style={{ margin: 0 }}>€ {categoria}+</p>
                                : <p style={{ margin: 0 }}>{categoria}</p>}
                        </li>
                    )
                })}
            </ul>
            <div className="contenitoreOrizzontale">
                <p className="pages" ref={top}>
                    {index.a == 0 ? <ion-icon name="radio-button-off-outline"></ion-icon>
                        : <ion-icon name="caret-back-circle-outline" onClick={() => Refresh("previous")} />}
                    ESPLORA
                    {full && index.b == prodotti.length - 1 ? <ion-icon name="radio-button-off-outline"></ion-icon>
                        : <ion-icon name="caret-forward-circle-outline" onClick={() => Refresh("next")} />}
                </p>
            </div>
            <div className="contenitoreOrizzontale">
                {prodotti.slice(index.a, index.b + 1).map((prodotto) => {
                    return (
                        <article key={prodotto["id"]} className="prodotto">
                            <Link to={"/Catalogo/" + prodotto["ID prodotto + ID combinazione"]}
                                className="image" onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}>
                                <img src={prodotto["URL immagini"].split(",")[0]} loading="lazy" />
                            </Link>
                            <Link to={"/Catalogo/" + prodotto["ID prodotto + ID combinazione"]}
                                style={{ textDecoration: "none" }} onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}>
                                <h1 className="seeMore">
                                    {prodotto["Nome prodotto"]}
                                </h1>
                            </Link>
                            <p style={{ margin: "0" }}> {prodotto["Nome attributo"] ?
                                "Taglia" + ": " + prodotto["Valore attributo"]
                                : null}
                            </p>
                            <div className="price">
                                <h3>€ {arrotonda(prodotto["Prezzo (consigliato)"].toFixed(2))}</h3>
                                <button onClick={() => { cart.addToCart(prodotto["ID prodotto + ID combinazione"]) }}
                                    className="addToCart" value="aggiungi al carrello">
                                    <ion-icon name="bag-add-outline"></ion-icon> Aggiungi
                                </button>
                            </div>
                        </article>
                    )
                })}
            </div>
            <div className="contenitoreOrizzontale">
                <p className="pages">
                    {index.a == 0 ? <ion-icon name="radio-button-off-outline"></ion-icon>
                        : <ion-icon name="caret-back-circle-outline" onClick={() => { top.current.scrollIntoView(); Refresh("previous") }} />}
                    ESPLORA
                    {full && index.b == prodotti.length - 1 ? <ion-icon name="radio-button-off-outline"></ion-icon>
                        : <ion-icon name="caret-forward-circle-outline" onClick={() => { top.current.scrollIntoView(); Refresh("next") }} />}
                </p>
            </div>
        </div>
    );
}