import "../css/generale.css";
import "../css/Prodotto.css";
import Prodotti from "../components/Prodotti";
import Preview from "../components/Preview";

export default function Catalogo(props) {
    return (
        <div className="contenitoreVerticale">
            {props.preview ?
                <div style={{ width: "100%" }}>
                    <Preview display="flex" />
                    <Prodotti display="none" />
                </div>
                :
                <div style={{ width: "100%" }}>
                    <Preview display="none" />
                    <Prodotti display="flex" />
                </div>
            }
        </div>
    )
}