import { useAuth } from "../contexts/AuthContext";
import { useContext, useEffect, useState } from "react";
import { CartContext } from "../contexts/CartContext";
import { db } from "../firebase-config";
import { query, collection, where, getDocs, getDoc, doc } from "firebase/firestore";
import "../css/cartList.css"
import { eseguiCheckout } from "../firebase-config";
import { arrotonda } from "../index.js"
import { Link } from "react-router-dom";

export default function Carrello() {
    const { currentUser } = useAuth()
    const cart = useContext(CartContext)
    const productCount = cart.items.reduce((sum, product) => sum + product.quantity, 0)
    const idSet = cart.items.map(
        product => product.id // FA RIFERIMENTO A 'ID prodotto'
    )
    const [productData, setProductData] = useState({})
    const [loading, setLoading] = useState(false)
    const [costoSpedizione, setCostoSpedizione] = useState()
    const [sogliaSpedizioneGratuita, setSogliaSpedizioneGratuita] = useState()
    const [spedizioneRisultante, setSpedizioneRisultante] = useState(costoSpedizione)
    const [costoTotale, setCostoTotale] = useState(0)
    const [cartFooter, setCartFooter] = useState("")

    const asyncIterable = {
        [Symbol.asyncIterator]() {
            let i = 0;
            return {
                next() {
                    const done = i === idSet.length;
                    const value = done ? undefined : i++;
                    return Promise.resolve({ value, done });
                },
                return() {
                    // This will be reached if the consumer called 'break' or 'return' early in the loop.
                    return { done: true };
                },
            };
        },
    };

    useEffect(() => {
        const getInfo = async () => {
            const informazioni = await getDoc(doc(db, "gestione", "infoBase"));
            setCostoSpedizione(informazioni.data()["costoSpedizione"])
            setSogliaSpedizioneGratuita(informazioni.data()["sogliaSpedizioneGratuita"])
            setCartFooter(informazioni.data()["cartFooter"])
        }
        getInfo()
    }, [])

    useEffect(() => {
        const getProdotti = async () => {
            for await (let i of asyncIterable) {
                const q = query(collection(db, "sexyshopingrosso"),
                    where("ID prodotto + ID combinazione", "==", idSet[i]));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    setProductData(prevProductData => ({
                        ...prevProductData,
                        [idSet[i]]: doc.data()
                    }))
                })
            }
        }
        getProdotti()
    }, [cart])

    useEffect(() => {
        let subtotale = 0
        cart.items.map((cartItem) => {
            if (!isNaN(productData?.[cartItem.id]?.["Prezzo (consigliato)"]))
                subtotale += arrotonda(productData?.[cartItem.id]?.["Prezzo (consigliato)"]) * cartItem.quantity
        })
        subtotale = subtotale.toFixed(2)
        if (subtotale > sogliaSpedizioneGratuita) {
            setSpedizioneRisultante(0)
            setCostoTotale(parseFloat(subtotale))
        }
        else {
            setSpedizioneRisultante(costoSpedizione)
            setCostoTotale(parseFloat((parseFloat(subtotale) + parseInt(costoSpedizione) / 100)).toFixed(2))
        }
    })

    return (
        <div className="contenitoreVerticale">
            <h1> <ion-icon name="cart-outline"></ion-icon> Il tuo carrello: </h1>
            <ul className="cartList" style={{ flexDirection: "column" }}>
                {productCount > 0 ?
                    <>
                        {cart.items.map((currentProduct) =>
                            <li key={currentProduct.id} >
                                <div className="addButton">
                                    <ion-icon onClick={() => cart.removeFromCart(currentProduct.id)}
                                        name="remove-circle-outline"></ion-icon>
                                    <h2> {currentProduct.quantity} </h2>
                                    <ion-icon onClick={() => cart.addToCart(currentProduct.id)}
                                        name="add-circle-outline"></ion-icon>
                                </div>
                                <a href={"/#/catalogo/".concat(currentProduct.id)} target="_blank">
                                    <div>
                                        <img src={productData?.[currentProduct.id]?.["URL immagini"]}></img>
                                    </div>
                                    <div className="caption">
                                        <h1 style={{ textAlign: "left" }}>
                                            {
                                                productData?.[currentProduct.id]?.["Nome prodotto"] == null
                                                    ?
                                                    "non disponibile"
                                                    :
                                                    productData?.[currentProduct.id]?.["Nome prodotto"]}
                                            {productData?.[currentProduct.id]?.["Nome attributo"] ?
                                                <><br />{"★ Taglia: " + productData?.[currentProduct.id]?.["Valore attributo"]}</>
                                                : null
                                            }
                                        </h1>
                                        <h2>{
                                            isNaN(productData?.[currentProduct.id]?.["Prezzo (consigliato)"])
                                                ?
                                                ""
                                                :
                                                "€ " + (arrotonda(productData?.[currentProduct.id]?.["Prezzo (consigliato)"]) * currentProduct.quantity).toFixed(2)
                                        }</h2>
                                    </div>
                                </a>
                            </li>
                        )}
                        <li>
                            <div className="caption" id="caption">
                                <div style={{display:"flex", flexDirection: "row", gap:"1vh"}}>
                                    <ion-icon name="subway-outline"></ion-icon>
                                    <h1>
                                        Spedizione:
                                    </h1>
                                    <h2>{spedizioneRisultante > 0 ?
                                        "€ " + (spedizioneRisultante / 100).toFixed(2)
                                        : "GRATUITA"}
                                    </h2>
                                </div>
                                <div>
                                    <h1>{spedizioneRisultante > 0 ?
                                        " mancano solo €" + (sogliaSpedizioneGratuita - costoTotale + costoSpedizione/100).toFixed(2) +
                                        " per ottenere la spedizione gratuita!"
                                        : ""}
                                    </h1>
                                </div>
                            </div>
                        </li>
                        <hr />
                        <li>
                            <div style={{ textAlign: "center" }}>
                                <p> totale: </p>
                                <p> <b>€ {costoTotale} </b></p>

                            </div>
                            {
                                currentUser && currentUser.emailVerified ?
                                    <button
                                        onClick={() => {
                                            eseguiCheckout(cart.items, productData, spedizioneRisultante, currentUser.uid);
                                            setLoading(true)
                                        }}
                                        disabled={loading ? "disabled" : ""} className="addToCart">
                                        <ion-icon name="arrow-forward-outline"></ion-icon>
                                        {loading ? "attendere..." : "Procedi all'ordine"}
                                    </button>
                                    :
                                    currentUser && !currentUser.emailVerified ?
                                        <button
                                            onClick={() => {
                                                if (currentUser) {
                                                    eseguiCheckout(cart.items, productData, spedizioneRisultante, currentUser.uid);
                                                    setLoading(true)
                                                }
                                            }}
                                            disabled className="addToCart">
                                            <Link to="/dashboard" style={{ color: "white" }}>
                                                Conferma prima la tua email!
                                            </Link>
                                        </button>
                                        :
                                        <button
                                            onClick={() => {
                                                if (currentUser) {
                                                    eseguiCheckout(cart.items, productData, spedizioneRisultante, currentUser.uid);
                                                    setLoading(true)
                                                }
                                            }}
                                            disabled className="addToCart">
                                            <Link to="/login" style={{ color: "white" }}>
                                                Effettua il login per fare acquisti
                                            </Link>
                                        </button>
                            }
                        </li>
                    </>
                    : <h1 style={{ color: "grey" }}> Ancora nulla qui! <br /> Aggiungi qualcosa dal nostro catalogo!</h1>
                }
            </ul>
            <br />
            <br className="hideOnMobile"/>
            <hr /> <br />
            <p style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{
                    __html: cartFooter
                        .replace("{1}", sogliaSpedizioneGratuita)
                        .replace("{2}", (costoSpedizione / 100).toFixed(2))
                        .replace("[", '<a href="/#/about">')
                        .replace("]", '</a>')
                }}>
            </p>
        </div>
    )
}
