import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase-config";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

export default function Articolo(props) {
    const params = useParams()
    const [nomeArticolo, setNomeArticolo] = useState();
    const [articolo, setArticolo] = useState();
    const [immagine, setImmagine] = useState()

    useEffect(() => {
        setNomeArticolo(params.articolo)
    })

    useEffect(() => {
        const getProdotto = async () => {
            const docSnap = await getDocs(query(collection(db, "blog"),
                where("nome", "==", params.articolo)))
            try {
                setArticolo(docSnap.docs.map((doc) => ({ ...doc.data() }))[0])
            } catch {
                console.log("Prodotto non trovato.");
            }
        }
        if (nomeArticolo) getProdotto();
    }, [nomeArticolo])

    useEffect(() => {
        if (articolo) {
            setImmagine(articolo["imgURL"])
        }
    }, [articolo])

    return (
        <div className="contenitoreVerticale" style={{ display: props.display }} id="preview">
            <Link to="/" className="pages" style={{ textDecoration: "none" }}
                onClick={() => { setNomeArticolo(""); setImmagine([]); setArticolo() }}>
                <ion-icon name="backspace-outline" />
                <p>indietro</p>
            </Link>
            {articolo ?
                <article key={articolo["id"]} className="prodotto" id="prodottoBig">
                    <div className="image">
                        <img src={immagine} loading="lazy" alt="" />
                    </div>
                    <br />
                    <div className="articolo" style={{ cursor: "auto" }}>
                        <h1 style={{ marginBottom: "0" }}> {articolo["nome"]} </h1>
                        <p> <i>{"data pubblicazione: " + articolo["data"]}</i> </p>
                        <p dangerouslySetInnerHTML={{ __html: articolo["descrizione"]}}/>
                        <br />
                    </div>
                </article>
                :
                null
            }
            <Link to="/" className="pages" style={{ textDecoration: "none" }}
                onClick={() => { setNomeArticolo(""); setImmagine([]); setArticolo() }}>
                <ion-icon name="backspace-outline" />
                <p>indietro</p>
            </Link>
        </div >
    )
}