import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import OrderHistory from "../components/OrderHistory";
import "../css/form.css";
import AdminPanel from "../components/AdminPanel";

export default function Dashboard() {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const { currentUser, login, logout, updatemail, updatepw } = useAuth()
    const oldEmail = useRef()
    const newEmail = useRef()
    const pw = useRef()
    const confirmPw = useRef()
    const pwAttuale = useRef()
    const navigate = useNavigate()

    async function handleEmailUpdate(e) {
        e.preventDefault()
        setError('')
        if (oldEmail.current.value == newEmail.current.value) return setError("Email già in uso!")
        else {
            setLoading(true)
            const nuovaEmail = newEmail.current.value
            const passwordRichiesta = pwAttuale.current.value
            try {
                await updatemail(nuovaEmail, passwordRichiesta)
                if(currentUser.email == nuovaEmail) window.location.reload(true)
                else setError("Password non corretta")
            } catch {
                setError("Qualcosa è andato storto!")
            }
            setLoading(false)
        }
    }

    async function handlePasswordUpdate(e) {
        e.preventDefault()
        setError('')
        if (pw.current.value != confirmPw.current.value) return setError("Le password non corrispondono!")
        setLoading(true)
        try {
            await updatepw(pw.current.value)
            setError("Password modificata con successo.")
        } catch {
            setError("Qualcosa è andato storto!")
        }
        setLoading(false)
    }

    async function handleLogout(e) {
        setError('')
        setLoading(true)
        e.preventDefault()
        try {
            await logout()
            navigate("/")
            window.scrollTo({ top: 0, behavior: "instant" })
        } catch {
            setError("Qualcosa è andato storto durante il logout!")
        }
        setLoading(false)
    }

    return (
        <div className="contenitoreVerticale">
            <h1>Ciao {currentUser.email}!</h1>
            {   // SEZIONE ADMIN
                currentUser.uid == "0HSd2yvfLzSIuNQ6NWRLmkNIEXy1" ?
                    <div>
                        <p className="hideOnDesktop"> sei da mobile: ruota il telefono per il pannello admin </p>
                        <div className="hideOnMobile"><AdminPanel /></div>
                    </div>
                    : null
            }
            <div className="formCompatto">
                <OrderHistory />
            </div>
            <div className="formCompatto">
                <div className="contenitoreVerticale">
                    <br />
                    <div className="contenitoreOrizzontale" 
                        style={{ width: "80%", display: "flex", justifyContent: "space-around" }}>
                        <form id="small" onSubmit={handleEmailUpdate}>
                            <label> <ion-icon name="create-outline"></ion-icon> modifica <u>email</u>: </label>
                            <input ref={oldEmail} type="email" className="form" value={currentUser.email} disabled></input>
                            <input ref={newEmail} type="email" className="form" placeholder="nuova email" required></input>
                            <input ref={pwAttuale} type="password" className="form" placeholder="password" required></input>
                            <input type="submit" className="form" id="submit" value="modifica" disabled={loading}></input>
                        </form>
                        <br className="hideOnDesktop" /><br className="hideOnDesktop" />
                        <form onSubmit={handlePasswordUpdate} id="small">
                            <label> <ion-icon name="create-outline"></ion-icon> modifica <u>password</u>: </label>
                            <input ref={pw} type="password" className="form" placeholder="nuova password" minLength="6" required></input>
                            <input ref={confirmPw} type="password" className="form" placeholder="conferma password" minLength="6" required></input>
                            <input type="submit" className="form" id="submit" value="modifica" disabled={loading}></input>
                        </form>
                        <br className="hideOnDesktop" />
                    </div>
                    {error &&
                <h2 style={{ display: "flex", alignItems: "center" }}> {error} </h2>}
                </div>
            </div>
            <form onSubmit={handleLogout} className="contenitoreVerticale">
                <input type="submit" className="form" id="submit" value="Esci" disabled={loading}
                    style={{ backgroundColor: "salmon" }}></input>
            </form>
        </div>
    )
}